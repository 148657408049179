import { createTheme } from "@mui/material";

const LightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#FF9E00",
        },
        secondary: {
            main: "#7f8a95",
        },
    },
    typography: {
        fontFamily: ["Open Sans"].join(","),
    },
    components: {
        // Name of the component
        MuiButtonBase: {
            defaultProps: {
                // The props to change the default for.
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    fontFamily: "Oswald, sans-serif",
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: "contained" },
                    style: {
                        backgroundColor: "#FF9E00",
                        color: "white",
                    },
                },
            ],
        },
        MuiButtonGroup: {
            defaultProps: {
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    marginBottom: "10px",
                    borderBottom: "#888 solid 1px",
                    color: "#888",
                    paddingLeft: "20px",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    fontFamily: "Oswald, sans-serif",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontFamily: "Oswald, sans-serif",
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: "Oswald, sans-serif",
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: "Oswald, sans-serif",
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#222",
                    padding: "18px",
                    color: "#fff",
                },
            },
        },
        MuiSpeedDial: {
            styleOverrides: {
                fab: {
                    backgroundColor: "#FF9E00",
                    color: "white",
                },
            },
        },
        MuiSpeedDialAction: {
            styleOverrides: {
                staticTooltipLabel: {
                    backgroundColor: "white",
                    color: "#222",
                },
            },
        },
    },
});

export default LightTheme;
